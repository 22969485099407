<template>
  <el-dialog
    title="基本设置"
    :visible.sync="dialogFormVisible"
    width="65%"
    top="1%"
    @close="doClose"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <h3 class="h3">基本信息</h3>
      <el-form-item
        label="题库名称："
        label-width="100px"
        prop="questionBankName"
      >
        <el-input
          v-model="ruleForm.questionBankName"
          placeholder="请输入题库名称"
          maxlength="50"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="行政区划：" label-width="100px" prop="areaId">
        <el-cascader
          v-model="ruleForm.areaId"
          :options="areatreeList"
          :props="propsarea"
          :disabled="disabled"
          clearable
          filterable
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="题库类别：" label-width="100px" prop="categoryCode">
        <el-cascader
          v-model="ruleForm.categoryCode"
          :options="TreeList"
          :props="propsTree"
          :disabled="disabled"
          clearable
          filterable
          size="small"
        ></el-cascader>
        <!-- <treePopupQuestion
          ref="tree"
          @eventBtn="childBack"
          :ruleForm="ruleForm"
          size="small"
          @clearParams="clearParams"
          :modal="false"
          type
        /> -->
      </el-form-item>
      <el-divider></el-divider>
      <h3 class="h3">题库标签</h3>
      <el-form-item label="添加标签：" label-width="100px" prop="tagName">
        <el-input
          v-model="ruleForm.tagName"
          type="textarea"
          placeholder="请输入题库标签"
        ></el-input>
      </el-form-item>
      <p style="padding-left:100px">
        用户可根据该标签关键字搜索到题库,多个关键字可用逗号分割,每个关键字的长度不多于8个字符
      </p>
      <el-divider></el-divider>
      <h3 class="h3">题库价格</h3>
      <el-form-item
        label="安卓价格(元)："
        label-width="130px"
        prop="androidPrice"
      >
	    <!-- <el-input
          v-model="ruleForm.androidPrice"
          maxlength='7'
          size="small"
        >
        </el-input> -->
      <el-select v-model="ruleForm.androidPrice" placeholder="请选择安卓价格(元)" size="small">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="IOS价格(元)：" label-width="130px" prop="iosPrice">
        <el-select v-model="ruleForm.iosPrice" placeholder="请选择IOS价格(元)" size="small">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小程序价格(元)：" label-width="130px" prop="miniappPrice">
		  <!-- <el-input
          v-model="ruleForm.miniappPrice"
          maxlength='7'
          size="small"
        >
        </el-input> -->
        <el-select   v-model="ruleForm.miniappPrice"  placeholder="请选择小程序价格(元)" size="small">
          <el-option
          oninput="value=value.replace(/[^\d]/g,'')"

            v-for="(item,index) in options"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-divider></el-divider>
      <h3 class="h3">售卖规则</h3>
      <el-form-item label="有效期：" label-width="100px" prop="saleRuleData">
        <el-input
          placeholder="请输入售卖有效期"
          v-model="ruleForm.saleRuleData"
          maxlength='7'
          oninput="value=value.replace(/[^\d]/g,'')"
          size="small"
        >
          <span slot="append">天</span>
        </el-input>
      </el-form-item>
      <el-form-item label="免费试做：" label-width="100px" prop="practiceNum">
        <el-input
          placeholder="请输入免费试做道数"
          v-model="ruleForm.practiceNum"
          maxlength='2'
          oninput="value=value.replace(/[^\d]/g,'')"
          size="small"
        >
          <span slot="append">道</span>
        </el-input>
      </el-form-item>
       <p style="padding-left:100px">
       免费试做题数不超过99道。
      </p>
      <el-divider></el-divider>
      <h3 class="h3">分享送时长规则</h3>
      <el-form-item
        label="每分享1人注册成功后，送时长："
        prop="sharedRuleData"
        label-width="250px"
      >
        <el-input
          placeholder="请输入赠送时长"
          v-model="ruleForm.sharedRuleData"
          size="small"
          maxlength='9'
          oninput="value=value.replace(/[^\d]/g,'')"
        >
          <span slot="append">分钟</span>
        </el-input>
      </el-form-item>
      <el-divider></el-divider>
      <h3 class="h3">banner图</h3>
      <el-form-item
        label="上传图片："
        prop="questionbankBanner"
        class="bannerBox"
      >
        <el-upload
          action
          :file-list="ruleForm.fileList"
          list-type="picture-card"
          :on-change="handlePictureCardPreview"
          :before-upload="beforeAvatarUpload2"
          :http-request="$requestMine"
          limit="5"
          class="df"
          :class="{ hide: hideUploadCard }"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <h3 class="h3">是否上架</h3>
      <el-form-item label="是否上架：" prop="questionBankState">
        <el-switch
          :width="20"
          v-model="ruleForm.questionBankState"
          active-value="10"
          inactive-value="20"
          active-color="#13ce66"
        ></el-switch>
        <span>{{ ruleForm.questionBankState == "10" ? "上架" : "下架" }}</span>
      </el-form-item>
      <el-form-item>
        <div class="flexcc">
          <el-button @click="doClose()">取 消</el-button>
          <el-button class="bgc-bv" type="primary" @click="doOk"
            >确 定</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
// import treePopupQuestion from "@/components/treePopupQuestion.vue";
export default {
  name: "questionbankInformation",
  components: {
    // treePopupQuestion,
  },
  data() {
    return {
      dialogFormVisible: false,
      areatreeList: [],
      TreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      propsTree: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: false,
      },
      options:[
        {
        value:0,
        label:0
      },
        {
        value:6,
        label:6
      },
        {
        value:12,
        label:12
      },
        {
        value:18,
        label:18
      },
        {
        value:25,
        label:25
      },
        {
        value:30,
        label:30
      }
      ],
      //基本设置
      ruleForm: {
        questionBankName: "", //题库名称
        areaId: "", //行政区划
        categoryCode: "",
        tagName: "",
        androidPrice: "12",
        iosPrice: "12",
		miniappPrice:"12",
        saleRuleData: "365",
        sharedRuleData: "30",
        thumbnail: [],
        srcImg: [],
        questionBankState: "20",
        fileList: [],
        practiceNum:'', //免费试做
      },
      hideUploadCard: false,
      questionBankId: "",
      params: {},
      rules: {
        questionBankName: [
          {
            required: true,
            message: "请输入题库名称长度最多50字",
            trigger: "blur",
          },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        categoryCode: [
          { required: true, message: "请选择题库类别", trigger: "change" },
        ],
        tagName: [
          {
            required: true,
            message: "请输入题库标签并用逗号分隔",
            trigger: "blur",
          },
        ],
        androidPrice: [
          { required: true, message: "请输入安卓售价", trigger: "blur" },
          { required: true, validator: this.validatePass6, trigger: "blur" },
        ],
        iosPrice: [
          { required: true, message: "请输入苹果售价", trigger: "blur" },
          { required: true, validator: this.validatePass6, trigger: "blur" },
        ],
        miniappPrice: [
          { required: true, message: "请输入小程序售价", trigger: "blur" },
          { required: true, validator: this.validatePass6, trigger: "blur" },
        ],
        saleRuleData: [
          { required: true, message: "请输入有效期", trigger: "blur" },
        ],
        sharedRuleData: [
          { required: true, message: "请输入分享时长", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.getareatree();
    this.getTree();
  },
  methods: {
    validatePass6(rule, value, callback) {
      if (value > 9999999) {
        callback(
          new Error("价格必须是0.01~9999999之间的数字，最多保留两位小数。")
        );
      } else {
        callback();
      }
    },
    showPopUp(questionBankId) {
      this.dialogFormVisible = true;
      this.questionBankId = questionBankId;
      if (questionBankId && questionBankId != undefined) {
        this.getInformation(questionBankId);
      }
    },
    /* 获取行政区划start */
    getareatree() {
      this.$post("/sys/area/tree?areaLevel=1", {}, 3000, true, 2).then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    /* 获取行政区划end */
    /* 五棵树treePopupQuestion start */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    /* 五棵树treePopupQuestion end */
    /* 上传图片start */
    handlePictureCardPreview(res, fileList) {
      console.log(fileList);
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("folder ", "COURSE_WARE");
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.srcImg.push(result.data.fileURL);
            this.ruleForm.thumbnail.push(result.data.fileKey);
            console.log(this.ruleForm.thumbnail);
            this.hideUploadCard =
              this.ruleForm.thumbnail.length == 5 ? true : false;
          })
          .catch(() => {
            return;
          });
      }
    },
    /* 上传图片end */
    /* 删除图片start */
    handleRemove(res, fileList) {
      this.ruleForm.thumbnail = [];
      fileList.map((item) => {
        this.ruleForm.thumbnail.push(item.name);
      });
      this.hideUploadCard = fileList.length == 5 ? true : false;
    },
    /* 删除图片end */
    /* 基本信息回显数据 */
    getInformation(questionBankId) {
      this.$post("/question/bank/query", { questionBankId }, 3000, false, 2)
        .then((ret) => {
          let retData = ret.data;
          this.ruleForm.questionBankName = retData.questionBankName;
          this.ruleForm.areaId = retData.areaId;
          this.ruleForm.categoryCode = retData.categoryCode;
          this.ruleForm.tagName = retData.tagChinese;
          this.ruleForm.thumbnail = retData.bannerContents || [];
          this.ruleForm.androidPrice = retData.androidPrice;
          this.ruleForm.iosPrice = retData.iosPrice;
          this.ruleForm.miniappPrice = retData.miniappPrice;
          this.ruleForm.saleRuleData = retData.saleRuleData;
          this.ruleForm.sharedRuleData = retData.sharedRuleData;
          this.ruleForm.questionBankState = retData.questionBankState;
          this.ruleForm.practiceNum = retData.practiceNum;
          // if (retData.trainTypeId) {
          //   this.params.trainFormId = retData.trainTypeId;
          // }
          // if (retData.postId) {
          //   this.params.postFormId = retData.postId;
          // }
          // if (retData.industryId) {
          //   this.params.industryFormId = retData.industryId;
          // }
          // if (retData.occupationId) {
          //   this.params.occFormId = retData.occupationId;
          // }
          // if (retData.trainLevelId) {
          //   this.params.levelFormId = retData.trainLevelId;
          // }
          for (var i = 0; i < retData.bannerContents.length; i++) {
            this.ruleForm.fileList.push({
              name: retData.bannerContents[i],
              url: retData.bannerKey[i],
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    doOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.showtitle = false;
          const that = this;
          let parmar = {
            questionBankName: that.ruleForm.questionBankName,
            areaId: that.ruleForm.areaId,
            categoryCode: that.ruleForm.categoryCode,
            androidPrice: that.ruleForm.androidPrice,
            iosPrice: that.ruleForm.iosPrice,
            miniappPrice: that.ruleForm.miniappPrice,
            saleRuleData: that.ruleForm.saleRuleData,
            sharedRuleData: that.ruleForm.sharedRuleData,
            bannerContents: that.ruleForm.thumbnail,
            questionBankState: that.ruleForm.questionBankState,
            tagName: that.ruleForm.tagName,
          };
          if (that.questionBankId) {
            parmar.questionBankId = that.questionBankId;
          }
          if (that.ruleForm.practiceNum) {
            parmar.practiceNum = that.ruleForm.practiceNum;
          }
          that
            .$post("/question/bank/save", parmar, 3000, true, 2)
            .then((ret) => {
              that.doClose();
              // that.questionBankId = ret.data
              // that.$emit("getnewdatap");
              // that.$emit("subShows", true);
              that.parentPsBack(ret.data);
              if (ret.status == 0) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: ret.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    doClose() {
      this.$refs["ruleForm"].resetFields();
      this.dialogFormVisible = false;
      this.ruleForm = {
        questionBankName: "", //题库名称
        areaId: "", //行政区划
        categoryCode: "", //题库类型
        practiceNum: "",
        tagName: "",
        androidPrice: "",
        iosPrice: "",
		miniappPrice:"",
        fileList: [],
        saleRuleData: "365",
        sharedRuleData: "",
        thumbnail: [],
        srcImg: [],
        questionBankState: "20",
      };
    },
    // 给父组件传参
    parentPsBack(id) {
      this.$emit("eventBus", id);
      this.doClose();
    },
    getTree() {
      this.$post('/miniapp/category/enable/tree',{disabledLevels:'1,2,3'},3000, true, 2).then(ret => {
        this.TreeList = ret.data
      })
    }
  },
};
</script>
