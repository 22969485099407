<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="5%"
    width="50%"
    title="批量添加"
    :center="true"
    :before-close="doClose"
  >
    <div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="从Excel上传" name="first">
            <questionbankExcel
            ref="questionbankExcel"
            :questionBankId="this.questionBankId"
            @eventBusOk="formBack"
            @eventBusClose="doClose"
          />
        </el-tab-pane>
        <!-- <el-tab-pane label="从题库添加" name="second">
          <paperForm
            ref="paperForm"
            @eventBusOk="formBack"
            @eventBusClose="doClose"
          />
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </el-dialog>
</template>
<script>
// import paperTable from "@/views/resourse/popup/PaperTable";
import questionbankExcel from "@/views/Questionbank/questionbankExcel";

export default {
  name: "questionbankUpload",
  components: {
    questionbankExcel,
    // paperTable
  },
  data() {
    return {
      dialogVisible: false,
      activeName: "first",
      stu: false,
    };
  },
  computed: {},
  methods: {
    showPopUp(questionBankId) {
      this.dialogVisible = true;
      this.questionBankId = questionBankId;
    },
    doClose() {
      this.dialogVisible = false;
    },
    // tab回传
    formBack(id) {
      this.parentPsBack(id);
    },
    
    // 给父组件传参
    parentPsBack(id) {
      this.$emit("eventBus", id);
      this.doClose();
    }
  }
};
</script>
<style lang="less"></style>
