<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf">题库列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ $route.query.stu == "edit" ? "编辑" : "新增" }}题库</a
          >
          <p v-html="$xss(test)"></p>
        </span>
      </div>
      <div class="framePage-halfBody">
        <div class="framePage-article">
          <div class="ovy-a">
            <div>
              <div>
                <div class="title">
                  <h3>基本信息</h3>
                  <el-button
                    type="text"
                    class="title-button"
                    @click="setInformation"
                    >设置</el-button
                  >
                </div>
                <div class="titleMation">
                  <span>题库名称:{{ questionbankName }}</span>
                  <span>行政区划:{{ areaName }}</span>
                  <span>题库类别:{{ categoryName }}</span>
                </div>
              </div>
              <div>
                <div class="title">
                  <h3>题库类型</h3>
                  <el-button
                    type="text"
                    class="title-button"
                    @click="clickTap(-1, '')"
                    >全部</el-button
                  >
                </div>
                <div class="searchBox">
                  <el-input
                    v-model="questionTitle"
                    type="text"
                    size="small"
                    placeholder="请输入题目内容"
                    clearable
                  >
                    <el-button slot="append" class="bgc-bv" @click="getData()"
                      >搜索</el-button
                    ></el-input
                  >
                </div>
                <div class="searchqusetionBox">
                  <div
                    v-for="(item, index) in items"
                    :key="index"
                    @click="clickTap(index, item.id)"
                  >
                    <span :class="currentClass(index)">
                      <img :src="item.img" />
                      {{ item.name }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="btnBox">
                <el-button type="primary" class="bgc-bv" @click="addquestion"
                  >新增试题</el-button
                >
                <el-button type="primary" class="bgc-bv" @click="BatchUpload"
                  >批量添加</el-button
                >
              </div>
              <div v-if="!!questionBankId">
                <div class="title">
                  <h3>组卷设置</h3>
                  <el-button
                    type="text"
                    class="title-button"
                    @click="paperSettingFromSave"
                    >保存组卷设置</el-button
                  >
                </div>
                <div class="searchBox">
                  <el-form ref="paperSettingFrom" size="mini" :model="paperSettingFrom" label-width="calc(2em + 22px)">
                    <el-row v-for="(item,index) in paperSettingFrom.typeConfigs" :key="item.questionType">
                      <el-col :span="12">
                        <el-form-item 
                          :label="item.questionType | filterFromItem" 
                          :prop="'typeConfigs.' + index + '.questionNum'"
                          >
                          <el-input-number
                            v-model.number="item.questionNum"
                            :precision="0"
                            :min="0"
                            :controls="false"
                            @blur="blurInput(item.questionNum,item.questionType)"
                            style="width: calc(100% - 2em);margin-right: 0.5em;"
                          ></el-input-number>道
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item 
                          label="每题"
                          :prop="'typeConfigs.' + index + '.questionScore'"
                          >
                          <el-input-number
                            v-model.number="item.questionScore"
                            :precision="2"
                            :min="0"
                            :controls="false"
                            @blur="()=> {if(item.questionScore === null || item.questionScore ===undefined || item.questionScore ==='') item.questionScore = 0}"
                            style="width: calc(100% - 2em);margin-right: 0.5em;"
                          ></el-input-number>分
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label-width="0">
                          共 {{questionTotal}} 道题  合计 {{questionTotalScore}} 分
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label="及格分数" label-width="calc(4em + 12px)">
                          <el-input-number
                            v-model="paperSettingFrom.configPassScore"
                            :precision="2"
                            :min="0"
                            :controls="false"
                            style="width: 5em;margin-right: 0.5em;"
                          ></el-input-number>分
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label="时间限制" label-width="calc(4em + 12px)">
                          <el-input-number
                            v-model="paperSettingFrom.configTimeLimit"
                            :precision="0"
                            :min="0"
                            :controls="false"
                            style="width: 5em;margin-right: 0.5em;"
                          ></el-input-number>分
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="framePage-body">
          <div class="ovy-a">
            <div class="searchbox">
              <div class="flexcb searchbox" style="justify-content: center">
                <div style="position: absolute; left: 39px">
                  <el-link
                    v-for="(item, index) in qbquestionAuditList"
                    :key="index"
                    @click="getSessionsList(item.value, index)"
                    :type="qbquestionAuditIndex == index ? 'primary' : ''"
                    :underline="false"
                    style="margin-left: 10px"
                    >{{ item.label }}</el-link
                  >
                  <!-- <el-link  @click="getSessionsList('1')" :type="stus == '1'?'primary':''" :underline="false">待审核</el-link>
                  <el-link  @click="getSessionsList('2')" :type="stus == '2'?'primary':''" :underline="false" style="margin-left:10px">已审核</el-link>
                  <el-link  @click="getSessionsList('3')" :type="stus == '3'?'primary':''" :underline="false" style="margin-left:10px">审核不通过</el-link> -->
                </div>
                <h2 style="text-align: center">{{ questionbankName }}</h2>
              </div>
            </div>
            <div
              class="framePage-scroll flexdc"
              :style="!tableData.length ? 'height:93%' : ''"
            >
              <div class="ovy-a flex1" id="list-box">
                <div
                  class="list hoverShow"
                  v-for="(item, index) in tableData"
                  :key="index"
                  v-show="tableData.length > 0"
                >
                  <div class="list-item df">
                    <span
                      class="tixing"
                      :class="{
                        tixing1: item.qbQuestion.questionType == 0,
                        tixing2: item.qbQuestion.questionType == 1,
                        tixing3: item.qbQuestion.questionType == 2,
                        tixing4: item.qbQuestion.questionType == 3,
                      }"
                      >{{
                        $setDictionary(
                          "QUESTIONTYPE",
                          item.qbQuestion.questionType
                        )
                      }}</span
                    >
                    <div class="subject flex1">
                      <span
                        style="
                          display: flex;
                          justify-content: flex-end;
                          padding-bottom: 10px;
                        "
                        >试题来源:{{
                          $setDictionary(
                            "QUESTIONORIGIN",
                            item.qbQuestion.questionOrigin
                          )
                        }}</span
                      >
                      <div class="subject-title">
                        <span
                          v-html="$xss(item.qbQuestion.questionTitle)"
                          style="display: flex"
                          >{{ item.qbQuestion.questionTitle }}</span
                        >
                      </div>
                      <div
                        class="option"
                        v-if="item.qbQuestion.questionType == 0"
                      >
                        <span
                          v-for="(items, indexs) in item.qbQuestionOptions"
                          :key="indexs"
                          >{{ letterArr[indexs] }}.{{ items.optionContent }}
                          <el-image
                            v-if="items.optionSpecialContent"
                            class="qbimg"
                            :src="
                              items.optionSpecialContent ||
                              require('@/assets/develop.png')
                            "
                            fit="contain"
                          ></el-image>
                        </span>
                      </div>
                      <div
                        class="option"
                        v-if="item.qbQuestion.questionType == 1"
                      >
                        <span
                          v-for="(items, indexs) in item.qbQuestionOptions"
                          :key="indexs"
                          >{{ letterArr[indexs] }}.{{ items.optionContent }}
                          <el-image
                            v-if="items.optionSpecialContent"
                            class="qbimg"
                            :src="
                              items.optionSpecialContent ||
                              require('@/assets/develop.png')
                            "
                            fit="contain"
                          ></el-image
                        ></span>
                      </div>
                      <div class="analysis">
                        正确答案：<span
                          v-show="item.qbQuestionOptions.length != 0"
                          v-for="(items, indexs) in item.qbQuestionOptions"
                          :key="indexs"
                          >{{
                            items.optionIsCorrect ? letterArr[indexs] : ""
                          }}</span
                        >
                        <span v-show="item.qbQuestionOptions.length == 0">{{
                          item.qbQuestion.questionAnswer
                        }}</span>
                      </div>
                      <div style="display: flex">
                        <p>答案解析：</p>
                        <span
                          class="analysis img-boxs"
                          style="flex: 1"
                          v-html="$xss(item.qbQuestion.questionAnalysis)"
                          >{{ item.qbQuestion.questionAnalysis }}</span
                        >
                      </div>
                      <div class="df showbtn" style="float: right">
                        <div style="white-space: nowrap">
                          <el-button
                            style="padding: 8px 20px"
                            v-show="stuAudit == 'audit'"
                            @click="
                              onAudit(item.qbQuestion.questionId, true, 'pass')
                            "
                            >审核通过</el-button
                          >
                          <el-button
                            style="padding: 8px 20px"
                            v-show="stuAudit == 'audit'"
                            @click="
                              onAudit(item.qbQuestion.questionId, false, 'fail')
                            "
                            >审核不通过</el-button
                          >
                          <el-button
                            style="padding: 8px 20px"
                            v-show="
                              index !== 0 &&
                              TopictypeVal == '' &&
                              questionTitle == ''
                            "
                            @click="onSort(item.qbQuestion.questionId, 'top')"
                            >上移</el-button
                          >
                          <el-button
                            style="padding: 8px 20px"
                            v-show="
                              index !== tableData.length - 1 &&
                              TopictypeVal == '' &&
                              questionTitle == ''
                            "
                            @click="
                              onSort(item.qbQuestion.questionId, 'bottom')
                            "
                            >下移</el-button
                          >
                          <el-button
                            style="padding: 8px 20px"
                            @click="onEdit(item.qbQuestion.questionId)"
                            >编辑</el-button
                          >
                          <el-button
                            style="padding: 8px 20px"
                            @click="onDel(item.qbQuestion.questionId)"
                            >删除</el-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Empty2 slot="empty" v-show="!tableData.length" />
            </div>
          </div>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
        </div>
      </div>
    </div>
    <!-- 基本信息设置弹层start -->
    <questionbankInformation
      ref="questionbankInformation"
      @subShows="subShows"
      @eventBus="questiionbankBacks"
    />
    <!-- end -->
    <!-- 新增试题弹层start -->
    <questionPaperPop
      ref="questionPaperPop"
      @getnewdatap="getData"
      @subShows="subShows"
    />
    <!-- end -->
    <!-- 编辑试题弹层start -->
    <questionPaperEditPop
      ref="questionPaperEditPop"
      @getnewdatap="getData"
      @subShows="subShows"
    />
    <!-- end -->
    <!-- 批量上传start -->
    <questionPaper ref="questionPaper" @eventBus="paperBack" />
    <!-- end -->
    <!-- 审核不通过 -->
    <el-dialog
      title="审核不通过"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-input
          type="textarea"
          placeholder="请输入试题审核不通过原因"
          maxlength="1000"
          v-model="auditReason"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty2 from "@/components/Empty2.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import questionPaperPop from "@/views/Questionbank/questionPaperPop.vue";
import questionPaperEditPop from "@/views/Questionbank/questionPaperEditPop.vue";
import questionPaper from "@/views/Questionbank/questionbankUpload.vue";
import questionbankInformation from "@/views/Questionbank/questionbankInformation.vue";
export default {
  name: "questionbankAdd",
  components: {
    Empty2,
    PageNum,
    questionPaperPop,
    questionPaperEditPop,
    questionPaper,
    questionbankInformation,
  },
  mixins: [List],
  data() {
    return {
      test: `<a onclick='alert("xss攻击")'>链接</a>`,
      letterArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "X",
        "Y",
        "Z",
      ],
      currentNumber: -1,
      questionbankName: "",
      areaName: "",
      categoryName: "",
      items: [
        {
          img: require("@/assets/radio.png"),
          name: "单选题",
          id: "0",
        },
        {
          img: require("@/assets/check.png"),
          name: "多选题",
          id: "1",
        },
        {
          img: require("@/assets/judge.png"),
          name: "判断题",
          id: "2",
        },
        {
          img: require("@/assets/fill.png"),
          name: "填空题",
          id: "3",
        },
      ],
      TopictypeVal: "",
      questionTitle: "",
      stu: "", // 状态,
      JsonRoute: "",
      dialogVisible: false,
      auditReason: "",
      qbquestionAuditList: [],
      audit: "40",
      qbquestionAuditIndex: 0,
      questionBankId:'',
      paperSettingFrom:{}//组卷设置数据
    };
  },
  computed:{
    // 共多少道题
    questionTotal(){
      if(this.paperSettingFrom?.typeConfigs?.length>0){
        let num = 0
        for (const item of this.paperSettingFrom.typeConfigs) {
          num += Number(item.questionNum)
        }
        return num
      }
    },
    // 共多少分
    questionTotalScore(){
      if(this.paperSettingFrom?.typeConfigs?.length>0){
        let num = 0
        for (const item of this.paperSettingFrom.typeConfigs) {
          num += (Number(item.questionNum)*Number(item.questionScore))
        }
        num = num.toFixed(2)
        return num
      }
    },
  },
  created: function () {
    this.getqbquestionAuditList();
    if (sessionStorage.getItem("questionJson")) {
      this.JsonRoute = JSON.parse(sessionStorage.getItem("questionJson"));
    }
    // this.JsonRoute = JSON.parse(this.$route.query.json);
    if (this.$route.query.stu == "edit") {
      this.questionbankName = this.JsonRoute.questionBankName;
      this.questionBankId = this.JsonRoute.questionBankId;
      this.areaName = this.JsonRoute.areaName;
      this.categoryName = this.JsonRoute.questionCategory;
      this.stuAudit = this.JsonRoute.stuAudit;
      // 获取题库组卷配置
      this.getPaperConfig(this.questionBankId)
    } else {
      this.questionbankName = "" || this.JsonRoute.questionBankName;
      this.questionBankId = "" || this.JsonRoute.questionBankId;
      this.areaName = "" || this.JsonRoute.areaName;
      this.categoryName = "" || this.JsonRoute.questionCategory;
      this.getData();
    }
  },
  filters: {
    filterFromItem(type){
      let res
      switch (type) {
        case '0':
          res = '单选'
          break;
        case '1':
          res = '多选'
          break;
        case '2':
          res = '判断'
          break;
        case '3':
          res = '填空'
          break;
        default:
          res = '未知'
          break;
      }
      return res
    },
  },
  methods: {
    getqbquestionAuditList() {
      const qbquestionAudit =
        this.$setDictionary("QB_QUESTION_AUDIT", "list") || [];
      console.log(qbquestionAudit);
      let list = [];
      for (const key in qbquestionAudit) {
        list.push({
          value: key,
          label: qbquestionAudit[key],
        });
      }
      for (let i = list.length - 1; i >= 0; i--) {
        const element = list[i];
        console.log(element);
        this.qbquestionAuditList.push(element);
      }
      console.log(this.qbquestionAuditList);
    },
    getSessionsList(audit, index) {
      this.audit = audit;
      this.qbquestionAuditIndex = index;
      this.getData();
    },
    /* 获取数据start */
    getData(pageNum = 1) {
      console.log(this.audit);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        questionBankId: this.$route.query.questionBankId || this.questionBankId,
        audit: this.audit || "40",
      };
      if (this.TopictypeVal) {
        params.questionType = this.TopictypeVal;
      }
      if (this.questionTitle) {
        params.questionTitle = this.questionTitle;
      }
      console.log("params", params);
      this.doFetch(
        {
          url: "/qb/question/pageList",
          params,
          pageNum,
        },
        true,
        2
      );

      // this.getEdit(this.parperId);
    },
    /* end */
    // 获取题库组卷配置
    getPaperConfig(questionBankId){
        this.$post("/baseconfig/questionTypeConfig/question_bank/config", { questionBankId }, 3000, true, 2)
        .then((res) => {
          this.paperSettingFrom = res.data
        });
    },
    
    /**
     * @description: 题目数移除校验题目数量
     * @param {*} val 题目数量
     * @param {*} questionType 题目类型
     * @return {*}
     */
    blurInput(val,questionType){
      if(val === null || val ===undefined || val ==='') {this.paperSettingFrom.typeConfigs.find(e=>e.questionType===questionType).questionNum = 0}
      this.$post("/baseconfig/questionTypeConfig/question_bank/question/num", { questionBankId:this.questionBankId,questionType }, 3000, true, 2)
        .then((res) => {
          const PAPERTYPENAME = this.$options.filters['filterFromItem'] (questionType)
          if(res.data<val){
            this.$message.error(`${PAPERTYPENAME}题目数量不够，总数为${res.data}道！`)
            this.paperSettingFrom.typeConfigs.find(e=>e.questionType===questionType).questionNum = res.data
          }
        });
    },
    /* 题库类型切换数据 start*/
    clickTap(index, id) {
      this.currentNumber = index;
      this.TopictypeVal = id;
      this.questionTitle = "";
      this.getData();
    },
    /**
     * @description: 保存组卷设置
     * @return {*}
     */
    paperSettingFromSave(){
      this.$refs['paperSettingFrom'].validate((valid) => {
        if (valid) {
          if(this.questionTotalScore<this.paperSettingFrom.configPassScore){
              this.$message.error(`及格分数不能大于总分!`)
              return
          }
          let data = {
            ...this.paperSettingFrom
          }
          data.questionBankId = this.questionBankId
          data.configTotalScore = this.questionTotalScore
          this.$post("/baseconfig/questionTypeConfig/question_bank/save", data, 3000, true, 2)
          .then((res) => {
            this.$message.success('保存组卷设置成功！')
          });
        }
      });
    },
    currentClass(index) {
      return [this.currentNumber == index ? "addclass" : ""];
    },
    /* end */
    /* 新增试题start 编辑试题 */
    onEdit(questionId) {
      this.$refs.questionPaperEditPop.showPopUp(questionId);
    },
    addquestion() {
      if (this.questionBankId) {
        this.$refs.questionPaperPop.showPopUp(this.questionBankId);
      } else {
        this.$confirm("请先设置题库的基本信息!", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "bgc-bv",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$refs.questionbankInformation.showPopUp();
          })
          .catch(() => {
            return;
          });
      }
    },
    /* end */
    /* 上移&&下移start */
    onSort(questionId, stu) {
      this.$post(
        stu == "top"
          ? "/qb/question/moveUpQuestion"
          : "/qb/question/moveDownQuestion",
        { questionId },
        3000,
        true,
        2
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* end */
    /* 试题删除start */
    onDel(questionId) {
      const that = this;
      that
        .$confirm(
          "你确定要从" + that.questionbankName + "中删除该试题吗",
          "删除",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "confirmButtonClass",
            type: "warning",
          }
        )
        .then(() => {
          that
            .$post("/qb/question/delete", { questionId }, 3000, true, 2)
            .then((ret) => {
              if (ret.status == 0) {
                that.subShow = true;
                that.getData();
              } else {
                that.$message({
                  type: "error",
                  message: ret.message,
                });
              }
            });
        })
        .catch(() => {
          return;
        });
    },
    /* end */
    /* 批量上传start */
    BatchUpload() {
      if (this.questionBankId) {
        this.$refs.questionPaper.showPopUp(this.questionBankId);
      } else {
        this.$confirm("请先设置题库的基本信息!", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "bgc-bv",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$refs.questionbankInformation.showPopUp();
          })
          .catch(() => {
            return;
          });
      }
    },
    paperBack() {
      this.getData();
    },
    /* end */
    /* 设置题库基本信息start */
    setInformation() {
      this.$refs.questionbankInformation.showPopUp(this.questionBankId);
    },
    questiionbankBacks(data) {
      sessionStorage.setItem("questionJson", JSON.stringify(data));
      // this.JsonRoute.questionBankName = data.questionBankName
      this.questionbankName = data.questionBankName;
      this.areaName = data.areaName;
      this.categoryName = data.questionCategory;
      this.questionBankId = data.questionBankId;
      if(this.$route.query.stu == 'add'){
        this.getPaperConfig(this.questionBankId)
        this.$router.replace({
          path: "/web/appraisalInstitution/appraisalQuestionBankAdd",
          query: {
            stu: "edit",
            questionBankId:this.questionBankId
          },
        })
      }
    },
    /* end */
    /* 返回 */
    goHerf() {
      this.$router.push({
        path: "/web/Questionbank/questionbankList",
        query: {
          refresh: true,
        },
      });
    },
    //审核题
    onAudit(questionId, audit, stu) {
      this.questionId = questionId;
      console.log(audit);
      // this.audit = audit;
      let parmar = {
        questionId: questionId,
        audit: audit,
      };
      if (stu == "pass") {
        this.$post("/qb/question/audit", parmar, 3000, true, 2).then((ret) => {
          this.$message({
            message: ret.message,
            type: "success",
          });
          this.getData();
        });
      } else {
        this.dialogVisible = true;
      }
    },
    //不通过原因
    sure() {
      if (!this.auditReason) {
        this.$message({
          message: "请输入试题审核不通过原因",
          type: "warning",
        });
        return false;
      }
      const parmar = {
        questionId: this.questionId,
        reason: this.auditReason,
        audit: false,
      };
      this.$post("/qb/question/audit", parmar, 3000, true, 2)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.dialogVisible = false;
            this.auditReason = "";
            this.getData();
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 取消审核不用过弹框
    cancle() {
      this.dialogVisible = false;
      this.auditReason = "";
    },
  },
};
</script>
<style lang="less">
.el-upload-list__item {
  /deep/img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
.h3 {
  padding: 0.75rem 0;
}
</style>
<style lang="less" scoped>
.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-right: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 20%;
  min-width: 20rem;
  .title {
    display: flex;
    justify-content: space-between;
    position: relative;
    h3 {
      font-size: 0.85rem;
    }
    h3:before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -14px;
    }
    .title-button {
      padding: 0;
      font-size: 0.8rem;
    }
  }
  .searchBox {
    display: flex;
    margin: 15px 0;
  }
  .searchqusetionBox {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 50%;
      display: flex;
      margin: 15px 0;
      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
      .addclass {
        color: #4574f9;
      }
    }
  }
  .titleMation {
    display: flex;
    flex-direction: column;
    padding: 0.9rem 0 1.8rem 0;
    span:nth-child(2) {
      padding: 0.5rem 0;
    }
  }
  .btnBox {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        justify-content: space-between;
        .number {
          padding-left: 1rem;
        }
        img {
          width: 100px;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
          display: flex;
          align-items: center;
          .qbimg {
            width: 5rem;
          }
        }
      }
    }
  }
}
.img-boxs {
  img {
    width: 100px;
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.informationmBox {
  display: flex;
  justify-content: space-between;
}
.el-divider--horizontal /deep/ {
  margin: 12px 0;
}
.el-cascader /deep/ {
  width: 100%;
}
.el-form-item__content /deep/ .el-upload {
  width: 100px;
  height: 148px !important;
}
.bannerBox .el-form-item__content /deep/ .df {
  display: flex;
}
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card /deep/.el-upload-list__item-actions {
  z-index: 9999;
}
.showbtn {
  display: none;
}
.hoverShow :hover {
  .showbtn {
    display: block;
  }
}
/deep/ .el-input--mini .el-input__inner{
  height: 20px!important;
  line-height: 20px!important;
}
</style>
